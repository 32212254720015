/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Vilnius"), React.createElement("p", null, "Pour se loger, Vilnius s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lt/vilnius/senamiestis.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La vieille ville"), " : le quartier historique de Vilnius, autour de la place de la cathédrale et de la rue Pilies."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lt/vilnius/citycentre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre de Vilnius"), " : quartier qui englobe la ville nouvelle autour de l’avenue Gedimino."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lt/vilnius/uzupis.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Užupis"), ", à l’est : quartier bohème et république officieuse avec sa propre constitution."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lt/vilnius/naujamiestis.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Naujamiestis"), ", à l’ouest : ancien quartier industriel en plein renouveau culturel."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lt/vilnius/snipiskes.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Šnipiškės"), ", au nord : le quartier moderne dédié aux affaires."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
